export const roundUpByStep = (
    numberToRound: number,
    step: number,
    allowedSteps: number[],
    maximum?: number | undefined,
) => {
    let roundedNumber = 0;
    const maxFloatDigits = 5;

    if (allowedSteps.includes(numberToRound)) {
        roundedNumber = numberToRound;
    } else {
        const newNumberToRound = (parseFloat(numberToRound.toFixed(maxFloatDigits)) / 100) * 100;
        roundedNumber = Math.ceil(newNumberToRound / step) * step;
    }

    return maximum && roundedNumber > maximum ? maximum : roundedNumber;
};
